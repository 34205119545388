// I18n
import VueI18n from "vue-i18n";
import Vue from "vue";
import locale from "element-ui/lib/locale";
import { getLang } from "@/utils/lang";
 
// 引入 elementui 的多语言
import enLocale from "element-ui/lib/locale/lang/en.js";
import zhCnLocale from "element-ui/lib/locale/lang/zh-CN";
// 如果还有新的语言在下面继续添加
 
// 引入自己定义的 I18n 文件
import myI18nEn from "./i18n-en.json";
import myI18nZh from "./i18n-zh.json";
import myI18nTw from "./i18n-tw.json";
// 如果还有新的语言在下面继续添加
 
// 注册 vue-i18n
Vue.use(VueI18n);
 
export const getLanguage = () => {
  // 本地缓存获取
  let language = getLang();
  if (language) {
    return language;
  }
  // 浏览器使用语言
  language = navigator.language.toLowerCase();
  const locales = Object.keys(language);
  for (const locale of locales) {
    if (language.indexOf(locale) > -1) {
      return locale;
    }
  }
  return "zh-cn";
};
 
// 默认中文
const lang = "zh-CN";
const i18n = new VueI18n({
  locale: getLanguage(),
  messages: {
    // 会把myI18nZh的所有内容拷贝到zhCnLocale文件中
    "zh-CN": Object.assign(zhCnLocale, myI18nZh),
    "en-US": Object.assign(enLocale, myI18nEn),
	"zh-TW": Object.assign(myI18nTw),
    // 如果还有新的语言在下面继续添加
  },
});
 
locale.i18n((key, value) => i18n.t(key, value));
export default i18n;