<template>
  <div class="home" :class="{'mobile-main':isMobile}">
    <div class="home-nav">
		<img src="../assets/logo.png" class="logo-img"/>
		<img src="../assets/language.jpg" class="language-img" @click="isShowLan = !isShowLan"/>
	</div>
	<div style="height: 63px;"></div>
	
	<img  src="../assets/luno-featured-en-GB.png" class="featured-img" v-if="!isMobile"/>
	
	<img  src="../assets/luno-featured-en-GB.png" class="featured-mobile-img" v-if="isMobile"/>
	
	<div class="module-noe module-main">
		<h1 class="module-noe-tel font-color">{{$t('home1')}}</h1>
		<div class="btn-lsin">
			<div class="module-noe-btn pointers" @click="jump">{{$t('btn1')}}</div>
		</div>
		
	</div>
	
	<div class="module-main secure-list">
		<div class="secure-item">
			<img src="../assets/dianzan.png" class="secure-img"/>
			<div class="secure-tel">{{$t('home2')}}</div>
			<div class="secure-text">{{$t('home3')}}</div>
		</div>
		<div class="secure-item">
			<img src="../assets/anquan.png" class="secure-img"/>
			<div class="secure-tel">{{$t('home4')}}</div>
			<div class="secure-text">{{$t('home5')}}</div>
		</div>
		<div class="secure-item">
			<img src="../assets/shequ.png" class="secure-img"/>
			<div class="secure-tel">{{$t('home6')}}</div>
			<div class="secure-text">{{$t('home7')}}</div>
		</div>
	</div>
	
	<div class="module-main choose-mian">
		<div class="choose-left">
			<img src="../assets/security-device-hidden.png" class="choose-left-img"/>
		</div>
		<div>
			<div class="choose-title">{{$t('home8')}}</div>
			<div class="choose-text">{{$t('home9')}}。</div>
			<div class="choose-text">{{$t('home10')}}</div>
			<div class="choose-text">{{$t('home11')}}</div>
			
			<div class="choose-btns pointers" @click="jump">{{$t('btn1')}}</div>
		</div>
	</div>
	
	<div class="module-main way-mian">
		<div class="way-item">
			<div class="way-item-tel">{{$t('home12')}}</div>
			<div class="way-item-text">{{$t('home13')}}</div>
			<img src="../assets/sign-up.png" class="way-img"/>
		</div>
		<div class="way-item">
			<div class="way-item-tel">{{$t('home14')}}</div>
			<div class="way-item-text">{{$t('home15')}}</div>
			<img src="../assets/choose-your-crypto.png" class="way-img"/>
		</div>
		<div class="way-item">
			<div class="way-item-tel">{{$t('home16')}}</div>
			<div class="way-item-text">{{$t('home17')}}</div>
			<img src="../assets/buy-crypto.png" class="way-img"/>
		</div>
	</div>
	
	<div class="way-boom">
		<div class="way-btns pointers" @click="jump">{{$t('btn1')}}</div>
	</div>
	
	<div class="module-main study-main">
		<div class="study-tels">{{$t('home18')}}</div>
		<div class="study-texts">{{$t('home19')}}</div>
		
		<div class="study-lists">
			<div class="study-item" @click="jump">
				<img src="../assets/study1.jpg" class="study-img"/>
				<div class="study-info">
					<div class="study-name">{{$t('home20')}}</div>
					<div class="study-inserted">{{$t('home21')}}</div>
				</div>
				
				<img src="../assets/jiantou-rets.png" class="jiantou-img"/>
			</div>
			<div class="study-item" @click="jump">
				<img src="../assets/study2.jpg" class="study-img"/>
				<div class="study-info">
					<div class="study-name">{{$t('home22')}}</div>
					<div class="study-inserted">{{$t('home23')}}</div>
				</div>
				<img src="../assets/jiantou-rets.png" class="jiantou-img"/>
			</div>
			<div class="study-item" @click="jump">
				<img src="../assets/study3.jpg" class="study-img"/>
				<div class="study-info">
					<div class="study-name">{{$t('home24')}}</div>
					<div class="study-inserted">{{$t('home25')}}</div>
				</div>
				<img src="../assets/jiantou-rets.png" class="jiantou-img"/>
			</div>
		</div>
	</div>
	
	
	<div class="module-main make-main">
		<div class="make-tel">{{$t('home26')}}</div>
		<div class="make-mxy">{{$t('home27')}}</div>
		
		
		<div class="make-list">
			<div class="make-mune">
				<div class="make-mune-item">
					<div class="mune-img-s">
						<img src="../assets/guanli.png" class="make-mune-img"/>
					</div>
					
					<div>
						<div class="make-mune-name">{{$t('home28')}}</div>
						<div class="make-mune-text">{{$t('home29')}}</div>
					</div>
				</div>
				<div class="make-mune-item" style="margin-top: 30px;">
					<div class="mune-img-s">
						<img src="../assets/goumai.png" class="make-mune-img"/>
					</div>
					
					<div>
						<div class="make-mune-name">{{$t('home30')}}</div>
						<div class="make-mune-text">{{$t('home31')}}</div>
					</div>
				</div>
				<div class="make-mune-item" style="margin-top: 30px;">
					<div class="mune-img-s">
						<img src="../assets/tansuo.png" class="make-mune-img"/>
					</div>
					
					<div>
						<div class="make-mune-name">{{$t('home32')}}</div>
						<div class="make-mune-text">{{$t('home33')}}</div>
					</div>
				</div>
			</div>
			
			<div class="make-list-ret">
				<img src="../assets/multi-platform-portfolio-en-GB.png" class="make-ret-img"/>
			</div>
		</div>
	</div>
	
	<div class="cooperate-mian">
		<div class="module-main cooperate-content">
			<div class="cooperate-tel">{{$t('home34')}}</div>
			
			<div class="cooperate-list">
				<img src="../assets/the-banker-logo.png" style="width: 127px;height: 40px;"/>
				<img src="../assets/the-block-logo.png" style="width: 198px;height: 40px;"/>	
				<img src="../assets/cnbc-logo.png" style="width: 63px;height: 40px;"/>
				<img src="../assets/coindesk-logo.png" style="width: 208px;height: 40px;"/>
				<img src="../assets/cityam-logo.png" style="width: 173px;height: 40px;"/>
				<img src="../assets/financial-times-logo.png" style="width: 298px;height: 40px;"/>
			</div>
		</div>
	</div>
	
	<div class="home-time">{{$t('home35')}}</div>
	<div class="home-pzs">{{$t('home36')}}</div>
	
	<div class="module-main copyright-main">
		<div class="copyright-module">
			<div class="copyright-tel">{{$t('home37')}}</div>
			<div class="copyright-tel">{{$t('home38')}}</div>
			
			<div class="copyright-text">{{$t('home39')}}</div>
		</div>
	</div>
	
	<div style="height: 40px;"></div>
	
	<div class="language-main" v-show="isShowLan">
		<div class="language-item" v-for="(value,index) in lanList" :key="index"
		:class="{'lan-sels':index==lanIndex}" @click="changeLan(value,index)">
			{{value.text}}
		</div>
	</div>
  </div>
</template>

<script>
import { setLang, getLang } from "@/utils/lang";
export default {
  name: 'HomeView',
  
  data() {
	  return {
		  isMobile: false,
		  timer: null,
		  lanIndex:0,
		  lanList:[
			  {text:'English',value:'en-US'},
			  {text:'简体中文',value:'zh-CN'},
			  {text:'繁体中文',value:'zh-TW'},
		  ],
		  isShowLan:false
	  }
  },
  mounted() {
  	// 添加事件监听
  	this.isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
  	window.addEventListener('resize', this.handleResize);
	let lanVal = getLang()
	if(lanVal){
		let index = this.lanList.findIndex(item=>item.value==lanVal)
		if(index>-1){
			this.lanIndex = index
		}
	}else{
		this.lanIndex = 0
	}
  },
  methods: {
	  changeLan(data,index){
		if(this.lanIndex!=index){
			this.lanIndex=index
			this.isShowLan = false
			setLang(data.value)
			location.reload()
		}
	  },
	  handleResize() {
	  	clearTimeout(this.timer);
	  	this.timer = setTimeout(() => {
	  		this.isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
	  	}, 100);
	  },
	  
	  jump(){
		  this.$router.push({ name: 'about'});
	  }
  }
}
</script>

<style lang="scss">
@import '../assets/css/mobile.scss';
.font-color{
	color: #071278
}

.language-main{
	position: fixed;
	right: 0;
	top: 63px;
	background-color: rgb(28, 33, 39);
	z-index: 999;
	height: 250px;
	.language-item{
		width: 96px;
		padding: 20px 0px;
		text-align: center;
		color: #fff;
		font-size: 14px;
	}
	.lan-sels{
		color: #0cc280;
	}
}
.featured-mobile-img{
	width: 100%;
	height: 384px;
	margin-top: 60px;
	object-fit: cover;
	object-position: top left;
}
.copyright-main{
	padding: 0px 40px;
	.copyright-module{
		border: 1px solid #071278;
		padding: 24px;
		.copyright-tel{
			margin-top: 12px;
			color: #071278;
			font-size: 16px;
			font-weight: bold;
		}
		.copyright-text{
			margin-top: 12px;
			color: #071278;
			font-size: 16px;
		}
	}
}
.home-pzs{
	text-align: center;
	color: #4d62ab;
	font-size: 18px;
	margin-top: 12px;
	margin-bottom: 35px;
}

.home-time{
	text-align: center;
	color: #4d62ab;
	font-size: 18px;
	margin-top: 25px;
}

.cooperate-mian{
	margin-top: 70px;
	background-color: #f1f7fd;
	padding: 64px 0px;
	.cooperate-content{
		.cooperate-tel{
			color: #001e87;
			font-size: 24px;
			font-weight: bold;
		}
		.cooperate-list{
			display: flex;
			align-items: center;
			gap: 30px;
			margin-top: 20px;
			flex-wrap: wrap;
		}
	}
}
.make-main{
	padding-top: 120px;
	.make-list-ret{
		width: 760px;
		height: 328px;
		display: flex;
		align-items: center;
		justify-content: center;
		.make-ret-img{
			width: 495px;
			height: 328px;
		}
	}
	.make-list{
		margin-top: 50px;
		display: flex;
		align-items: center;
		gap: 30px;
		.make-mune{
			.make-mune-item{
				display: flex;
				gap: 30px;
				.make-mune-img{
					width: 26px;
					height: 26px;
				}
				.make-mune-name{
					color: #10251c;
					font-size: 18px;
					font-weight: bold;
				}
				.make-mune-text{
					color: #4d62ab;
					font-size: 18px;
					margin-top: 8px;
				}
			}
		}
	}
	.make-tel{
		color: #001e87;
		font-size: 40px;
	}
	.make-mxy{
		color: #4d62ab;
		font-size: 18px;
		margin-top: 10px;
	}
}
.study-main{
	padding-top: 120px;
	.study-tels{
		color: #001e87;
		font-size: 40px;
	}
	.study-texts{
		color: #4d62ab;
		font-size: 18px;
		margin-top: 10px;
	}
	.study-lists{
		margin-top: 50px;
		display: flex;
		align-items: center;
		gap: 20px;
		.study-item{
			flex: 1;
			border-radius: 10px;
			border: 1px solid #071278;
			position: relative;
			height: 460px;
			cursor: pointer;
			.jiantou-img{
				position: absolute;
				bottom: 16px;
				right: 16px;
				width: 16px;
				height: 16px;
			}
			.study-info{
				padding: 24px;
				.study-name{
					color: #001e87;
					font-size: 24px;
					font-weight: bold;
				}
				.study-inserted{
					color: #4d62ab;
					font-size: 18px;
					margin-top: 10px;
				}
			}
			.study-img{
				width: 100%;
				height: 213px;
				border-radius: 10px 10px 0px 0px;
			}
		}
	}
}

.way-boom{
	margin-top: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	.way-btns{
		width: 216px;
		height: 52px;
		border-radius: 25px;
		background-color: #162af1;
		text-align: center;
		line-height: 52px;
		color: #fff;
		font-size: 16px;
	}
}

.way-mian{
	padding-top: 160px;
	display: flex;
	align-items: center;
	gap: 20px;
	.way-item{
		flex: 1;
		border-radius: 10px;
		border: 1px solid #071278;
		height: 380px;
		position: relative;
		.way-img{
			position: absolute;
			width: 100%;
			bottom: 0;
			height: 232px;
		}
		.way-item-tel{
			padding-top: 24px;
			padding-left: 24px;
			color: #10215c;
			font-size: 20px;
			font-weight: bold;
		}
		.way-item-text{
			color: #10215c;
			font-size: 18px;
			padding-left: 24px;
			padding-top: 10px;
		}
	}
}
.choose-mian{
	padding-top: 120px;
	display: flex;
	align-items: center;
	gap: 30px;
	.choose-title{
		color: #001e87;
		font-size: 40px;
	}
	.choose-text{
		color: #4d62ab;
		font-size: 18px;
		margin-top: 18px;
	}
	.choose-left{
		width: 570px;
		height: 380px;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-shrink: 0;
		.choose-left-img{
			width: 185px;
			height: 100%;
		}
	}
	.choose-btns{
		width: 216px;
		height: 52px;
		border-radius: 25px;
		background-color: #162af1;
		text-align: center;
		line-height: 52px;
		color: #fff;
		font-size: 16px;
		margin-top: 30px;
	}
}
.secure-list{
	padding-top: 30px;
	display: flex;
	// align-items: center;
	gap: 100px;
	.secure-item{
		flex: 1;
		.secure-img{
			width: 24px;
			height: 24px;
		}
		.secure-tel{
			font-size: 18px;
			font-weight: bold;
			margin-top: 5px;
		}
		.secure-text{
			color: #4d62ab;
			font-size: 18px;
			margin-top: 5px;
		}
	}
}
.module-main{
	width: 50%;
	margin: 0 auto;
}

.featured-img{
	position: absolute;
	top: 160px;
	right: 0;
	width: 950px;
	height: 700px;
}
.module-noe{
	
	padding: 290px 0px;
	.module-noe-tel{
		font-size: 44px;
		font-weight: 400;
	}
	.module-noe-btn{
		width: 216px;
		height: 52px;
		border-radius: 25px;
		background-color: #162af1;
		text-align: center;
		line-height: 52px;
		color: #fff;
		font-size: 16px;
	}
	
}
.home-nav{
	height: 63px;
	background-color: #000;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0px 20px;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 10;
	.logo-img{
		width: 40px;
		height: 40px;
	}
	.language-img{
		width: 24px;
		height: 24px;
	}
}
</style>
